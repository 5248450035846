import React from "react";
import Read from "../components/blogs/Read";

const Blog = () => {
  return (
    <div>
      <Read />
    </div>
  );
};

export default Blog;
